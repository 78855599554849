import AdminHome from '../views/admin/pc/Home.vue';

export default [{
        path: "/admin",
        name: "Admin",
        component: AdminHome,
        meta: {
            requiresAuth: true
        }
    }

]
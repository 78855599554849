<template>
	<div :style="divStyle">
		<div class="header">您好，欢迎登录「东荣-OA智能办公系统」</div>
		<div class="main">
			<div class="profile-box"></div>
			<div class="login-box">
				<el-form :model="user" status-icon :rules="rules" ref="elForm" label-width="80px" style="margin-right: 30px;margin-top: 30px;">
					<el-form-item label="工号" prop="employeeNumber">
						<el-input v-model="user.employeeNumber" placeholder="请输入工号" style="width: 250px;"></el-input>
					</el-form-item>
					<el-form-item label="密码" prop="password">
						<el-input type="password" v-model="user.password" placeholder="请输入密码" show-password style="width: 250px;"></el-input>
					</el-form-item>
					<el-form-item label="拖动验证" prop="">
						<div class="form-inline-input" style="">
							<div class="code-box" id="code-box">
								<input type="text" v-model="user.slider" name="code" class="code-input" />
								<p></p>
								<span>></span>
							</div>
						</div>
					</el-form-item>
					<el-form-item>
						<div style="text-align: left;">
							<el-checkbox v-model="autoLogin"></el-checkbox>
							<span style="margin-left:4px;">7天内免登录</span>
						</div>
						<div><el-button type="primary" @click="submitLoginForm('elForm')" style="width: 250px;">登录</el-button></div>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="footer">Copyright © 东荣五金制品公司</div>
	</div>
</template>

<script>
//插件
//import $ass from "@/utils/assjs/ass.js";
import slider from "@/assets/prettyui/slider.js";
//接口
import { apiLogin } from "@/api/pub/user.js";

export default {
	data() {
		var checkEmpty = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("工号不能为空"));
			} else {
				callback();
			}
		};
		var validatePassword = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请输入密码"));
			} else {
				callback();
			}
		};
		return {
			divStyle: {
				backgroundImage: "url(" + require("@/assets/images/background/world_map_gear.jpg") + ")",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				//width: "100vw",
				height: "100vh"
			},
			autoLogin: true,
			user: {
				employeeNumber: "",
				password: ""
			},
			rules: {
				employeeNumber: [{ validator: checkEmpty, trigger: "blur" }],
				password: [{ validator: validatePassword, trigger: "blur" }]
			}
		};
	},
	methods: {
		initialize() {
			//console.log(window.location.pathname);
			if (window.location.pathname == "/") {
				//修改url为"/login""
				history.pushState(null, null, "/login");
			}
		},
		submitLoginForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					//console.log("user:", this.user);
					let box = document.querySelector("#code-box");
					let progress = box.querySelector("p");
					if (progress.innerText != "验证成功") {
						this.$message.error("请拖动滑块验证");
						return;
					}

					var formData = new FormData();
					formData.append("employee_number", this.user.employeeNumber);
					formData.append("password", this.user.password);
					//调用登录接口
					apiLogin(formData).then((response) => {
						if (response.data.code === 200) {
							this.$message({
								message: "登录成功！",
								type: "success"
							});

							let token = response.data.data.token;

							if (this.autoLogin) {
								//长期保存
								localStorage.setItem("token", token);
							} else {
								//浏览器关闭则失效
								sessionStorage.setItem("token", token);
							}
							//通过store共享
							this.$store.dispatch("setToken", token);

							//console.log(this.$route.query.target);
							if (this.$route.query.target) {
								//若携带target，则跳转到target
								location.href = this.$route.query.target;
							} else {
								//跳转到后台首页
								this.$router.push({ path: "/admin" });
							}
						} else {
							this.$message.error(response.data.message);
							console.log("response>>", response);
							slider.moveCode(true).resetCode();
							progress.innerText = "";
						}
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		sliderHandler() {
			let get = slider.moveCode(true);
			//console.log("get:", get);

			//code是后台传入的验证字符串
			//var code = "jsaidaisd656";
			//var codeFn = new moveCode(code);

			//获取当前的code值
			//console.log(codeFn.getCode());

			//改变code值
			//code = '46asd546as5';
			//codeFn.setCode(code);

			//重置为初始状态
			//codeFn.resetCode();
		}
	},
	mounted() {
		this.initialize();
		window.addEventListener("load", this.sliderHandler, true);
	},
	destroyed() {
		//销毁监听,否则监听会一直存在,
		window.removeEventListener("load", this.sliderHandler, true);
	}
};
</script>

<style lang="scss" scoped>
@import "@/assets/prettyui/slider.scss";

.header {
	top: 0;
	height: 60px;
	font-size: x-large;
	text-align: center;
	color: #ddd;
	padding-top: 12px;
}
.main {
	position: absolute;
	width: 100%;
	top: 60px;
	bottom: 46px;
	//background: #c6dbe8;
}
.footer {
	position: absolute;
	width: 100%;
	height: 46px;
	line-height: 46px;
	bottom: 0;
	text-align: center;
	color: #ccc;
	font-size: x-small;
	//background-color: #426374;
}

.login-box {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 4px;
	padding: 30px;
	z-index: 9;
}
a {
	text-decoration: none;
	color: #000000;
}
</style>

<template>
	<div></div>
</template>

<script>
export default {
	data() {
		return {};
	},
	methods: {},
	mounted() {}
};
</script>

<style lang="scss" scoped></style>

import Home from '../views/pub/pc/user/Login.vue'

export default [{
    path: '/',
    name: 'Index',
    component: Home,
    meta: {
        title: '欢迎登录「东荣-OA智能办公系统」'
    }
}, {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
        title: '欢迎登录「东荣-OA智能办公系统」'
    }
}, {
    path: "/login",
    name: "Login",
    // 懒加载
    component: () => import("../views/pub/pc/user/Login.vue"),
    meta: {
        title: '欢迎登录「东荣-OA智能办公系统」'
    }
}]